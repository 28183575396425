import React, { Fragment, useState } from 'react'
import { Grid, Button, Typography, Container, Card, CardContent, CircularProgress, Backdrop } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import store, { IAppStore } from '../state/store';
import service from '../api/services/genService';
import { IAction, IActionStatus, ICreateTypes,IFieldOption,ISection } from '../models/appDetails';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import WorkOrderSectionEditor from '../components/work-order-section/workOrderSectionEditor';
import WorkOrderSectionsGrid from '../components/work-order-section/workOrderSectionsGrid';
import WorkOrderForm from '../components/shared/work-order-form/workOrderForm';
import { WorkOrderFieldValue } from '../models/workOrderFieldValue';
import { WorkOrderPayloadResponse } from '../models/payloads/workorderPayloadResponse';
import { IWorkOrderPayloadRequest, WorkOrderPayloadRequest } from '../models/payloads/workorderPayloadRequest';
import { Notifications } from "../components/shared/notifications/Notifications";
import { AxiosResponse } from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { resetSearchAction } from '../state/actions/searchAction';
import { IWorkOrderSection, WorkOrderSection } from '../models/workOrderSection';
import { ActionType, OriginationSource } from '../models/enums';
import { IFieldsValidationResult } from '../models/workOrder';
import { resetAction } from '../state/actions/workOrderActions';
import ConfirmDialog from '../components/shared/notifications/ConfirmDialog';
import { Constants } from '../utils/constants';
import multipleSubmitService from '../api/services/multipleSubmitService';
import BulkWorkorderDialog from '../components/bulk-workorders/bulk-workorderDialog';
import { ISubmitWorkorderResponse } from '../models/payloads/submitWorkorderResponse';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		cardRoot: {
			minWidth: 275,
			marginTop: "20px",
			padding: "30px",
		},
		backdrop: {
      zIndex: theme.zIndex.drawer + 1,
    },
		headerContainer: {
			marginTop: "30px",
			marginBottom: "10px",
			paddingLeft: "40px",
			paddingRight: "40px"
		},
		container: {
			marginTop: "20px",
			marginBottom: "20px"
		},
		formPaper: {
			borderBottom: "0"
		},
		gridItem: {
			marginBottom: "1rem"
		},
		anchor: {
			textDecoration: "none"
		},
		subHeader: {
			fontWeight: "bold",
			color: "gray",
			opacity: "0.8"
		},
		gridcardRoot: {
			minWidth: 275,
			marginTop: "20px",
		},
		gridCard: {
			height: "400px",
			width: "100%",
			padding: 0,
			paddingBottom: "0 !important",
			overflowX: "auto"
		},
		loadIndicator: {
			marginTop: "25%" 
		}
	}),
);

interface IWorkOrderState {
	workOrder: Map<string, WorkOrderFieldValue>;
	sections: IWorkOrderSection[];	
	openDialog: boolean;
	cancelDialog: boolean;
	closeDialog: boolean;
	blockUI: boolean;
	newSection: boolean;
	dialogSection: IWorkOrderSection;
	isAdminClone?: boolean;
	workOrderSourceId?: any;
}

interface IWorkorderSaveResult {
	ok: boolean;
	error?: ISubmitWorkorderResponse;
	message? : string;
}

const WorkOrderPage = () => {
	const dispatch = useDispatch();
	const classes = useStyles();
	const history = useHistory();
	const notifications = new Notifications(React.useRef(null));
	const { action, type, id } = useParams<{ action: ActionType, type: string, id?: string }>();
	const { origin } = useLocation().state as { origin: OriginationSource };
	const woDetails = useSelector((state: IAppStore) => state.appDetails);
	const woState = store.getState().workOrder;
	
	const [workOrderState, setWorkOrderState] = React.useState<IWorkOrderState>({
		workOrder: woState.cloned?.workorder ?? new Map(),
		sections: woState.cloned?.sections ?? [],
		isAdminClone: woState.cloned?.isAdminClone ?? false,
		workOrderSourceId: woState.cloned?.workOrderSourceId ?? null,
		openDialog: false,
		cancelDialog: false,
		closeDialog: false,
		blockUI: false,
		newSection: false,
		dialogSection: new WorkOrderSection()
	});

	const [openBulkConfirm, setOpenBulkConfirm] = React.useState(false);
	const [openBulkDialog, setOpenBulkDialog] = useState(false);

	let workOrderFields: ICreateTypes | undefined;
	let pageAction: IAction | undefined;
	let actionStatus: IActionStatus | undefined;
	let validationDispatcher: {
		requiredValidation: () => IFieldsValidationResult,
		additionalValidation: () => IFieldsValidationResult,
	}

	const [submitButtonDisabled, setSubmitButtonDisabled] = React.useState(false);

	const updateFieldValues = (fieldValue: WorkOrderFieldValue) => {
		workOrderState.workOrder.set(fieldValue.name, fieldValue);
	}

	const getWorkOrder = async () => {
		const statusUrl = actionStatus?.endpoints?.fetch ?? "";
		const actionUrl = pageAction?.endpoints.fetch ?? "";

		const url = (statusUrl.length > 0 ? statusUrl : actionUrl).replace("{id}", id!);

		notifications.progress("Loading Work Order...");
		service.dynamicRequest.get(url!)
		.then(async result => {
			const workOrderResponse = new WorkOrderPayloadResponse(id as string, result as IWorkOrderPayloadRequest, workOrderFields as ICreateTypes);			
			setWorkOrderState({
				...workOrderState,
				workOrder: new Map(await workOrderResponse.getWorkOrder()),
				sections: await workOrderResponse.getSections()
			})
			notifications.kill();
		}).catch((err: AxiosResponse) => {
			console.log(err)
			notifications.error(err?.data || "There was a problem retrieving the WorkOrder");
			history.push("/");
		});
	}

	const setValidationDispatcher = (formKey: string, requiredDispatcher: () => IFieldsValidationResult, additionalValidationDispatcher: () => IFieldsValidationResult) => {
		validationDispatcher = {
			requiredValidation: requiredDispatcher,
			additionalValidation: additionalValidationDispatcher
		};
	}

	const handleSaveWorkOrder = async (isCancel? : boolean, progressMsg?: string, successMsg?: string) => {
		if (!isCancel && !validateWorkOrder()) {
			return;
		}

		setWorkOrderState({
			...workOrderState,
			workOrder: new Map(workOrderState.workOrder),
			sections: [...workOrderState.sections],
			cancelDialog: false,
			blockUI: true
		});

		notifications.progress(progressMsg || Constants.workOrderMessages.saveInProgress);
		var submitResult = await saveWorkorder();
		if (submitResult.ok) {
			if (!id && workOrderFields?.multiTitle?.active) {
				await checkForMultiSubmit();					
			} else {
				setWorkOrderState({
					...workOrderState,
					blockUI: false
				})
				handleCancelOnClick();
			}
			notifications.success(submitResult.message || Constants.workOrderMessages.saveSuccess);
		} else {
			if (isCancel) {
				const fieldValue = workOrderState.workOrder.get(workOrderFields?.submitProperties?.field || "");
				if (fieldValue)
					workOrderState.workOrder.set(
						fieldValue.name, 
						new WorkOrderFieldValue(fieldValue.field, workOrderFields?.submitProperties?.edit || ""));				
			}

			for (const field of submitResult.error?.fields ?? []) {
				const fieldDef = workOrderState.workOrder.get(field.name)?.field;
				if (fieldDef) {
					workOrderState.workOrder.set(field.name, new WorkOrderFieldValue(fieldDef, field.value))						
				}
			}

			setWorkOrderState({
				...workOrderState,
				workOrder: new Map(workOrderState.workOrder),
				sections: [...workOrderState.sections],
				cancelDialog: false,
				blockUI: false
			})			
		}
					
	};

	const checkForMultiSubmit = async () => {
		const titleField = workOrderFields?.multiTitle?.field ?? "";

		if ((titleField?.length ?? "") === 0) {
			console.error(Constants.titleCheckMessages.titleFieldNotSet);
		}

		const titleFieldValue = workOrderState.workOrder.get(titleField)?.getRequestValue();
		if (titleFieldValue) {
			try {
				var multipleSubmit = await multipleSubmitService.titleCheck(parseInt(titleFieldValue))		

				if (multipleSubmit.allowsMultiple) {
					setOpenBulkConfirm(true);
				} else {
					handleCancelOnClick();
				}
			} catch (err) {
				console.error((err as any)?.data || Constants.titleCheckMessages.requestGenericError);
				handleCancelOnClick();
			}				
		} else {
			console.error(Constants.titleCheckMessages.titleFieldValueNotSet);
			handleCancelOnClick();
		}

		setWorkOrderState({
			...workOrderState,
			blockUI: false
		});		
	}

	const saveWorkorder = async (titles?: IFieldOption[]): Promise<IWorkorderSaveResult> => {

		let payload: IWorkOrderPayloadRequest = new WorkOrderPayloadRequest(
			workOrderFields?.name as string,
			pageAction?.name!,
			workOrderState.workOrder, 
			workOrderState.sections,
			id).addTitles(titles ?? []);

		payload.isAdminClone = workOrderState.isAdminClone;
		payload.workOrderSourceId = workOrderState.workOrderSourceId;

		console.log({json: JSON.stringify(payload) });
		
		const statusUrl = actionStatus?.endpoints?.submit ?? "";
		const actionUrl = pageAction?.endpoints.submit ?? "";

		const url = statusUrl.length > 0 ? statusUrl : actionUrl;

		let submitResult: IWorkorderSaveResult = {
			ok: true
		}

		try {
			const response = await service.dynamicRequest.post(url!, payload);	
			submitResult.message = response.message;
		} catch (err) {
			submitResult.ok = false;
			submitResult.error = (err as any)?.data as ISubmitWorkorderResponse;

			notifications.error(submitResult.error?.message || "There was an error!");
		}

		return submitResult;
	}

	const handleResubmitWorkOrder = async () => {
		if (workOrderFields?.submitProperties.field) {
			const field = workOrderFields?.createFields.find(x => x.name === workOrderFields?.submitProperties.field);
			if (field) {
				workOrderState.workOrder.set(field.name, new WorkOrderFieldValue(field, workOrderFields?.submitProperties.create));
			}
		}

		const sectionSubmitProperties = workOrderFields?.section?.submitProperties;

		if (sectionSubmitProperties?.field) {
			workOrderState.sections.forEach(sec => {
				const secField = workOrderFields?.section?.createFields.find(x => x.name === sectionSubmitProperties?.field);
				if (secField) {
					sec.section.set(secField.name, new WorkOrderFieldValue(secField, sectionSubmitProperties?.create))
				}

				const subSectionSubmitProperties = workOrderFields?.section?.section?.submitProperties;
				if (subSectionSubmitProperties?.field) {
					sec.subSections.forEach(subSec => {
						const subSecField = workOrderFields?.section?.section?.createFields.find(x => x.name === subSectionSubmitProperties?.field);
						if (subSecField) {
							subSec.fieldValues.set(subSecField.name, new WorkOrderFieldValue(subSecField, subSectionSubmitProperties?.create))
						}
					})
				}
			})
		}

		await handleSaveWorkOrder(false, Constants.workOrderMessages.reSubmitInProgress, Constants.workOrderMessages.resubmitSuccess);
	}

	const handleCancelWorkOrder = () => {
		const fieldValue = workOrderState.workOrder.get(workOrderFields?.submitProperties?.field || "");
		if (fieldValue) {
			workOrderState.workOrder.set(fieldValue.name, new WorkOrderFieldValue(fieldValue.field, workOrderFields?.submitProperties?.delete || ""));
			handleSaveWorkOrder(true, Constants.workOrderMessages.cancelInProgress, Constants.workOrderMessages.cancelSuccess);
		}

	}

	const validateWorkOrder = () => {		

		let result = validationDispatcher.requiredValidation();

		if (result.fields.length === 1) {
			notifications.error(`Required field "${result.fields[0].name}" is missing!`);
			return false
		}

		if (result.fields.length > 0) {
			notifications.error(`Please enter required fields!`);
			return false
		}

		result = validationDispatcher.additionalValidation();

		if (result.fields.length > 0) {
			notifications.error(result.fields[0].message || "");
			return false
		}

		if (workOrderFields?.section?.required && workOrderState.sections.length == 0) {
			notifications.error(`${workOrderFields?.section?.display} are missing!`);
      return false
		}

		return true;
	}

	const handleSaveSection = (workOrderSection: IWorkOrderSection) => {
		const index = workOrderState.sections.findIndex(x => x.guid == workOrderSection.guid);
  
		if (index > -1) {
			workOrderState.sections[index] = workOrderSection;
		} else {
			workOrderState.sections.push(workOrderSection);
		}

		setWorkOrderState({
			...workOrderState,
			newSection: false,
			workOrder: new Map(workOrderState.workOrder),
			sections: [...workOrderState.sections],
			openDialog: false
		})
	}
	
	let error: any = null;

	if(woDetails.createTypes.length == 0)	error = "There is no definition for this app";
	workOrderFields = woDetails.createTypes.find(x => x.name === type);
	if (!workOrderFields) error = `There is no definition for the workorder type "${type}"`;
	
	pageAction = workOrderFields?.actions.find(x => x.name === action && x.originationSource == origin);
	if (!pageAction) error = `There is no definition for the action "${action}"`;

	if (!woState.status) {
		history.push("/");
		return null;
	}

	if (error) {
		notifications.error(error);
		history.push("/");
		return null;
	}

	actionStatus = pageAction?.statuses.find(x => x.value === woState.status);

	if (id && workOrderState.workOrder.size === 0) {
		getWorkOrder();

		return (
			<Fragment>
				<Grid container spacing={3} className={classes.loadIndicator} justify="center">
					<Grid item>
						<CircularProgress color="inherit" />
					</Grid>
				</Grid>
			</Fragment>
		)
	}	

	WorkOrderFieldValue.preSetFields(workOrderFields?.createFields, workOrderState.workOrder);

	if (workOrderFields?.submitProperties.field) {
		const field = workOrderFields?.createFields.find(x => x.name === workOrderFields?.submitProperties.field);
		const fieldValue = workOrderState.workOrder.get(workOrderFields?.submitProperties.field);
		if (field && (!fieldValue || fieldValue?.isNull)) {
			workOrderState.workOrder.set(field.name, new WorkOrderFieldValue(field, workOrderFields?.submitProperties.create));
		}
	}

	const handleAddOnClick = () => {
		setWorkOrderState({
			...workOrderState,
			newSection: true,
			workOrder: new Map(workOrderState.workOrder),
			openDialog: true,
			dialogSection: new WorkOrderSection()
		});
	}

	const closeDialog = () => {
		setWorkOrderState({
			...workOrderState,
			newSection: false,
			workOrder: new Map(workOrderState.workOrder),
			openDialog: false,
			dialogSection: new WorkOrderSection()
		});
	}

	const handleEditOnclick = (id: string) => {
		setWorkOrderState({
			...workOrderState,
			newSection: false,
			workOrder: new Map(workOrderState.workOrder),
			openDialog: true,
			dialogSection: workOrderState.sections.find(x => x.guid == id) as WorkOrderSection
		});
	}

	const handleDeleteOnclick = (id: string) => {
		const index = workOrderState.sections.findIndex(x => x.guid == id);

		if (workOrderFields?.section?.submitProperties.field) {
      const field = workOrderFields?.section.createFields.find(x => x.name === workOrderFields?.section?.submitProperties.field);
      const fieldValue = workOrderState.sections[index].section.get(workOrderFields?.section.submitProperties.field);
      if (field) {
        if (fieldValue?.textValue === workOrderFields?.section.submitProperties.edit) {
          workOrderState.sections[index].section.set(field.name, new WorkOrderFieldValue(field, workOrderFields?.section.submitProperties.delete));
          workOrderState.sections[index].deleted = true;
        } else {
					workOrderState.sections.splice(index, 1);
        }
      }
    }

		setWorkOrderState({
			...workOrderState,
			workOrder: new Map(workOrderState.workOrder),
			sections: [...workOrderState.sections]
		});
	}

	const handleCloneOnClick = (id: string) => {
		const sectionToClone = workOrderState.sections.find(x => x.guid == id);

		if (sectionToClone) {
			setWorkOrderState({
				...workOrderState,
				newSection: true,
				workOrder: new Map(workOrderState.workOrder),
				openDialog: true,
				dialogSection: sectionToClone?.clone(true, workOrderFields)
			});
		}

	}

	const handleCancelOnClick = () => {
		dispatch(resetSearchAction());
		dispatch(resetAction());
		setTimeout(() => {
			history.push("/");
		}, 100);
	}

	const handleBulkDialogOnClose = async (isConfirm = false) => {
		setOpenBulkConfirm(false);
		
		if (!isConfirm) {
			handleCancelOnClick();			
		} else {
			setOpenBulkDialog(true);
		}

	}

	const renderHeader = () => {
		let title = `Create new ${workOrderFields?.display} Work Order`;
		if (id) title = `Edit ${workOrderFields?.display} Work Order`;

		return (
			<Typography variant="h5" component="h5">{title}</Typography>
		)
	}

	const renderSaveButton = () => {		
		let btnDisplay = id ? "Update Work Order" : "Submit Work Order";

		if (actionStatus?.formFields.readOnly && actionStatus?.formFields.readOnlyExceptions.length === 0) {
			return null
		}

		return (
			<Grid item>
				<Button variant="outlined" disabled={submitButtonDisabled}
					color="primary" 
					onClick={() => handleSaveWorkOrder()}>{btnDisplay}</Button>
			</Grid>
		)
	}

	const renderResubmitButton = () => {
		let btnDisplay = "Re-Submit Work Order";

		if (!woState?.allowResubmit) {
			return null
		}

		return (
			<Grid item>
				<Button variant="outlined" 
					color="primary" 
					onClick={() => handleResubmitWorkOrder()}>{btnDisplay}</Button>
			</Grid>
		)
	}

	const renderCancelButton = () => {
		let btnDisplay = "Delete Work Order";

		if (!actionStatus?.cancel) {
			return null
		}

		return (
			<Grid item>
				<Button variant="outlined" 
					color="primary" 
					onClick={() => setOpenCancelDialog(true)}>{btnDisplay}</Button>
			</Grid>
		)
	}

	const renderAddBtn = () => {
		if (actionStatus && !actionStatus?.formFields.addSection) {
			return null;		
		}

		return (
			<Button variant="outlined" color="primary" onClick={handleAddOnClick}>
				Add {workOrderFields?.section?.display}
			</Button>
		)
	}

	const renderSectionGrid = () => {
		if (workOrderFields?.section?.name) {
			return (
					<Container className={classes.container} maxWidth={false}>
						{renderAddBtn()}
						<Card className={classes.gridcardRoot}>
							<CardContent className={classes.gridCard}>
								<WorkOrderSectionsGrid
									actionStatus={actionStatus}
									sectionDef={workOrderFields.section} 
									sections={workOrderState.sections}
									onEditClick={handleEditOnclick}
									onDeleteClick={handleDeleteOnclick}
									onCloneClick={handleCloneOnClick}/>
							</CardContent>
						</Card>
					</Container>
			);
		}

		return null;
	}

	const buildSectionDialog = () => {
    if (workOrderState.openDialog) {
      return(
        <WorkOrderSectionEditor
          section={workOrderFields?.section as ISection}
					mainValues={workOrderState.workOrder}
					sectionValues={workOrderState.dialogSection.clone()}
          open={workOrderState.openDialog}
					newRecord={workOrderState.newSection}
					actionStatus={actionStatus}
          closeDialog={closeDialog}
					saveSection={handleSaveSection}
        />
      )
    } 
  }

	const setOpenCancelDialog = (value: boolean) => {
		setWorkOrderState({
			...workOrderState,
			cancelDialog: value
		});
	}

	const setOpenCloseDialog = (value: boolean) => {
		setWorkOrderState({
			...workOrderState,
			closeDialog: value
		});
	}

	const handleOnSaveBulkDialog = async (selectedTitles: IFieldOption[]) => {
		setOpenBulkDialog(false);
		setWorkOrderState({
			...workOrderState,
			blockUI: true
		})

		notifications.progress(Constants.workOrderMessages.multipleSaveInProgress);
		var submitResult = await saveWorkorder(selectedTitles);

		setWorkOrderState({
			...workOrderState,
			blockUI: false
		})

		if (!submitResult.ok) {
			setOpenBulkDialog(true);
		} else {
			notifications.success(Constants.workOrderMessages.multipleSaveSuccess);
			handleCancelOnClick();
		}
	}

	return (
		<Fragment>
			<Backdrop className={classes.backdrop} open={workOrderState.blockUI} />
			<BulkWorkorderDialog
				mainTitleCpmProductId={workOrderState.workOrder.get(workOrderFields?.multiTitle?.field ?? "")?.getRequestValue()}
				open={openBulkDialog} 
				onClose={() => setOpenBulkDialog(false)} 
				onSave={handleOnSaveBulkDialog}
			/>
			<ConfirmDialog 
        title={`Additional episodes for ${workOrderFields?.display}`}
        body={`Would you like to add additional episodes to this work order?`}
        open={openBulkConfirm}
        onConfirm={() => handleBulkDialogOnClose(true)}
        onClose={() => handleBulkDialogOnClose()}
      />
			<ConfirmDialog 
        title="Close Workorder" 
        body={`Are you sure do you want to close and lose your changes?`}
        open={workOrderState.closeDialog}
        onConfirm={() => handleCancelOnClick()}
        onClose={() => setOpenCloseDialog(false)}
      />
			<ConfirmDialog 
        title="Request to Delete Work Order" 
        body={`This action will Delete your Work Order.  Are you sure you want to delete your Work Order?`}
        open={workOrderState.cancelDialog}
        onConfirm={() => handleCancelWorkOrder()}
        onClose={() => setOpenCancelDialog(false)}
      />
			{buildSectionDialog()}
				<Container className={classes.headerContainer} maxWidth={false}>
					<Grid container justify="space-between" 
						alignItems="center" >
						<Grid item className={classes.gridItem}>
							{renderHeader()}
						</Grid>
						<Grid item className={classes.gridItem}>
							<Grid container spacing={3}>
								<Grid item>
									<Button variant="outlined" color="primary" onClick={() => setOpenCloseDialog(true)}>Close</Button>
								</Grid>
								{renderCancelButton()}
								{renderResubmitButton()}
								{renderSaveButton()}
							</Grid>
						</Grid>
					</Grid>					
				</Container>

				<Container className={classes.container} maxWidth={false}>
						{/* <span className={classes.subHeader}>
							Enter the fields below to set up the creation of a new Work order:
						</span> */}
					<Card className={classes.cardRoot}>
						<CardContent>
							<WorkOrderForm
								key={`form-${workOrderFields?.name}`}
								gridSize={3}
								workOrderType={workOrderFields?.name}
								fields={workOrderFields?.createFields || []}
								initialfieldValues={workOrderState.workOrder}
								actionFormFields={actionStatus?.formFields}
								setParentFieldValues={updateFieldValues}
								setValidationDispatcher={setValidationDispatcher}
								setSubmitButtonDisabled={setSubmitButtonDisabled}
							/>
						</CardContent>
					</Card>
				</Container>

			{renderSectionGrid()}

		</Fragment>
	);
};

export default WorkOrderPage;
